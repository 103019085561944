import React, { useContext } from "react";
import { styled } from "styled-components";

import colors from "@/theme/colors";
import { useTranslate } from "@/i18n";
import AppContext from "@/contexts/appContext";

type Props = {
  savings: number;
  winbackUrl: URL;
};

const WinbackOffering: React.FC<Props> = (props) => {
  const { name } = useContext(AppContext).site;
  const t = useTranslate(messages);
  const { winbackUrl, savings } = props;

  return (
    <>
      <Title>{t("titleDowngrade")}</Title>
      <Content>
        <InfoText>
          <p>
            {t("paragraph", {
              siteName: name.full,
            })}
          </p>
        </InfoText>
        <ChangeButtonContainer>
          {savings > 0 && (
            <CtaHeading data-testid="winback-savings">
              {t("ctaHeading", { savings })}
            </CtaHeading>
          )}
          <CtaLink href={winbackUrl.href}>{t("ctaButtonDowngrade")}</CtaLink>
        </ChangeButtonContainer>
      </Content>
    </>
  );
};

export default WinbackOffering;

const Title = styled.h3`
  color: ${colors.white};
  margin: 0;
  text-transform: none;
`;

const Content = styled.div`
  display: flex;
  flex-flow: row wrap;
  column-gap: 40px;
  row-gap: 20px;
`;

const InfoText = styled.div`
  flex: 4;
  font-size: 14px;
  line-height: 1.6;
  min-width: min(300px, 100%);
  max-width: 100%;
`;

const ChangeButtonContainer = styled.div`
  flex: 2;
  display: flex;
  flex-flow: column wrap;
  gap: 20px;
  min-width: 170px;
`;

const CtaHeading = styled.p`
  flex: 0;
  font-size: 20px;
  margin: 0px;
`;

const CtaLink = styled.a`
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  border: 3px solid white;
  padding: 1em 2.5em;
  text-decoration: none;
  max-width: 270px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const messages = {
  ctaHeading: {
    nb: "Spar opptil {{savings}},- per mnd",
    nn: "Spar opptil {{savings}},- per mnd",
  },
  ctaButtonDowngrade: {
    nb: "Endre i dag",
    nn: "Endre i dag",
  },
  titleDowngrade: {
    nb: "Hva med et enklere abonnement?",
    nn: "Kva med eit enklare abonnement?",
  },
  paragraph: {
    nb: "I stedet for å si opp kan du endre til et enklere og billigere abonnement. Da kan du fortsette å lese alle artikler fra {{siteName}}, samtidig som du sparer penger.",
    nn: "I staden for å seia opp kan du endra til eit enklare og billigare abonnement. Då kan du halda fram med å lesa alle artiklar frå {{siteName}}, samtidig som du sparer pengar.",
  },
};
