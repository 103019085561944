import { styled } from "styled-components";
import React, { PropsWithChildren } from "react";

import ThemedText from "@/components/ThemedText";

type StyledPageHeaderProps = {
  justifyContent?: React.CSSProperties["justifyContent"];
};

const StyledPageHeader = styled.div<StyledPageHeaderProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent ?? "center"};
  margin-bottom: 30px;
  margin-top: 30px;
  & > * {
    margin: 0;
  }
`;

type Props = PropsWithChildren & {
  justifyContent?: React.CSSProperties["justifyContent"];
};

const PageHeader: React.FC<Props> = ({ justifyContent, children }) => (
  <StyledPageHeader justifyContent={justifyContent}>
    <ThemedText data-testid="page-header" as="h1" $textColor="secondary">
      {children}
    </ThemedText>
  </StyledPageHeader>
);

export default PageHeader;
