import { ComponentType, ComponentProps, PropsWithChildren } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { MdChevronRight } from "react-icons/md";
import { styled } from "styled-components";
import { Link } from "react-router-dom";

import colors from "@/theme/colors";

const LinkStyles = styled.span`
  display: flex;
  padding: 15px 25px;
  justify-content: space-between;
  text-decoration: none;
  color: ${colors.black};
  border-bottom: 1px solid ${colors.contrastGrayLight};
  background: ${colors.white};

  &:hover {
    background: ${colors.contrastGrayLight};
  }
`;

const ChevronRight = styled(MdChevronRight)`
  height: 22px;
  min-width: 22px;
`;

type LinkBlockProps = {
  "data-testid"?: string;
} & ComponentProps<typeof Link>;

export const LinkBlock: ComponentType<LinkBlockProps> = ({
  children,
  "data-testid": testId,
  ...rest
}) => {
  return (
    <Link {...rest}>
      <LinkStyles data-testid={testId}>
        {children}
        <ChevronRight />
      </LinkStyles>
    </Link>
  );
};

type ExternalLinkProps = PropsWithChildren & {
  href: string;
  icon?: boolean;
  testid?: string;
};

const ExternalLinkIcon = styled(FaExternalLinkAlt)`
  height: 0.85em;
  width: 0.85em;
  margin: 0 0 0 6px;
`;

export const ExternalLinkBlock: ComponentType<ExternalLinkProps> = ({
  href,
  icon = true,
  testid,
  children,
}) => (
  <a href={href} data-testid={testid}>
    <LinkStyles>
      <div>
        {children}
        {icon && <ExternalLinkIcon />}
      </div>
      <ChevronRight />
    </LinkStyles>
  </a>
);
