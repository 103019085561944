import React from "react";

import { pageTitle } from "@/utils/page";
import ErrorBlock from "@/components/ErrorBlock";
import { useTranslate } from "@/i18n";

const PageNotFound: React.FC = () => {
  const t = useTranslate(messages);
  pageTitle.set(t("title"));

  return (
    <ErrorBlock title={t("title")}>
      <p>{t("text")}</p>
    </ErrorBlock>
  );
};

const messages = {
  title: {
    nb: "Siden finnes ikke",
    nn: "Sidan finst ikkje",
  },
  text: {
    nb: "Det ser ut som denne siden ikke eksisterer. Kanskje adressen er stavet feil, eller så kan siden ha blitt slettet eller flyttet.",
    nn: "Det ser ut som denne sida ikkje eksisterer. Kanskje adressa er stava feil, eller så kan sidan ha vorte sletta eller flytta.",
  },
};

export default PageNotFound;
