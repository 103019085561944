import React from "react";
import { styled } from "styled-components";
import { logout } from "@amedia/user";
import { useOutletContext } from "react-router-dom";

import { Sheet } from "@/components/Sheet";
import ExternalLink from "@/components/ExternalLink";
import { TextButton } from "@/components/Button";
import { useTranslate } from "@/i18n";
import AidLogo from "@/components/AidLogo";
import { aidUrl } from "@/routes/external";
import { AppOutlet } from "@/components/AppRoute/appRoute";

const User: React.FC = () => {
  const t = useTranslate(messages);
  const { user } = useOutletContext<AppOutlet>();

  return (
    <Wrapper $primary>
      <UserHeaderSection>
        <UserName data-testid="user-full-name">{user.name.full}</UserName>
        <AidLogo height={30} width={30} />
      </UserHeaderSection>

      <div>
        <ExternalLink href={`${aidUrl}/aid/profil`}>
          {t("changeMessage")}
        </ExternalLink>
      </div>
      <div>
        <TextButton onClick={logout}>{t("logoutMessage")}</TextButton>
      </div>
    </Wrapper>
  );
};

export default User;

const Wrapper = styled(Sheet)`
  > *:not(:first-child) {
    margin-top: 25px;
  }
`;

const UserHeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const UserName = styled.h2`
  margin: 0;
`;

const messages = {
  changeMessage: {
    nb: "Endre opplysningene på aid.no",
    nn: "Endre opplysningane på aid.no",
  },
  logoutMessage: {
    nb: "Logg ut",
    nn: "Logg ut",
  },
  userError: {
    nb: "Greide ikke hente informasjon om aID-brukeren din",
    nn: "Greide ikkje hente informasjon om aID-brukaren din",
  },
};
