import React, { PropsWithChildren, useContext } from "react";
import { styled } from "styled-components";

import PageHeader from "../PageHeader";

import { messages } from "./messages";

import { useTranslate } from "@/i18n";
import AppContext from "@/contexts/appContext";

type Props = PropsWithChildren & {
  title: string;
};

const ErrorBlock: React.FC<Props> = ({ title, children }) => {
  const context = useContext(AppContext);

  const t = useTranslate(messages);

  const customerServicePhone = context.gaia.customerService.phone;
  return (
    <ErrorPageContainer data-testid="not-found-page">
      <PageHeader>{title}</PageHeader>
      {children && (
        <ErrorBodyContainer>
          <p style={{ marginBottom: "20px" }}>{children}</p>
          <CustomerServiceContainer className="customer-service">
            <PageHeader justifyContent="flex-start">
              {t("customerService")}
            </PageHeader>
            <p>{t("customerServicePhone", { phone: customerServicePhone })}</p>
            <p>{t("customerServiceOpeningHours")}</p>
            <p>
              <CustomerServiceLink href="/vis/info/qa">
                {t("faq")}
              </CustomerServiceLink>
            </p>
            <p>
              <CustomerServiceLink href="/vis/info/kontakt-kundesenteret">
                {t("request")}
              </CustomerServiceLink>
            </p>
          </CustomerServiceContainer>
        </ErrorBodyContainer>
      )}
    </ErrorPageContainer>
  );
};

const ErrorPageContainer = styled.div`
  marign: 0px;
`;

const CustomerServiceContainer = styled.div`
  margin-top: 30px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
`;

const ErrorBodyContainer = styled.div`
  padding: 25px;
  color: black;
  background: white;
`;

const CustomerServiceLink = styled.a`
  color: var(--brick-colors-utilityInteractionSubtleFg, #096bca) !important;
`;

export default ErrorBlock;
