import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Outlet,
  useNavigation,
  useLocation,
  Navigate,
} from "react-router-dom";
import { useContext, useEffect } from "react";

import AppContextProvider from "./components/AppContextProvider";
import MyPage from "./pages/MyPage/myPage";
import Spinner from "./components/Spinner";
import { routerPaths } from "./routes/routes";
import SubscriptionRoute from "./components/SubscriptionRoute";
import AddressChangesPage from "./pages/AddressChangesPage";
import ChangeAddressPage from "./pages/ChangeAddressPage";
import PauseDeliveryPage from "./pages/PauseDeliveryPage";
import DeliveryComplaintPage from "./pages/DeliveryComplaintPage";
import ReceiptPage from "./pages/ReceiptPage";
import ErrorPage from "./pages/ErrorPage";
import PaymentHistoryPage from "./pages/PaymentHistoryPage";
import ChangePaymentPage from "./pages/ChangePaymentPage";
import CallbackPage from "./pages/CallbackPage";
import CancelSubscriptionPage from "./pages/CancelSubscriptionPage";
import AppRoute from "./components/AppRoute/appRoute";
import PageNotFound from "./pages/PageNotFound";
import RedirectPage from "./pages/RedirectPage";
import TemporarilyUnavailable from "./components/TemporarilyUnavailable";
import { createSkipToContentLink } from "./utils/createSkipToContentLink";
import AppContext from "./contexts/appContext";

const Root = () => {
  const navigation = useNavigation();
  const { pathname } = useLocation();
  const { locale } = useContext(AppContext).site;

  // Makes sure each page change is rendered with scroll pos at the top
  useEffect(() => {
    if (document.querySelector("#skip-to-main-content") === null) {
      createSkipToContentLink(locale);
    }
    document.documentElement.scrollTo({ top: 0, left: 0 });
  }, [pathname, locale]);

  return (
    <AppContextProvider>
      {navigation.state === "loading" ? <Spinner /> : <Outlet />}
    </AppContextProvider>
  );
};
// <Approute> checks if user is logged in and fetches subscriptions
// <SubscriptionRoute> makes sure that the logged in user has subscription(s)
const mainRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="" element={<h1>This is Vorwerk!</h1>}></Route>
      <Route path="/" element={<Root />}>
        <Route element={<AppRoute />}>
          <Route path={routerPaths.myPage} element={<MyPage />} />

          <Route element={<SubscriptionRoute />}>
            <Route
              path={routerPaths.changeAddress}
              element={<ChangeAddressPage />}
            />
            <Route
              path={routerPaths.temporaryStop}
              element={<PauseDeliveryPage />}
            />
            <Route
              path={routerPaths.cancelSubscription}
              element={<CancelSubscriptionPage />}
            />
            <Route
              path={routerPaths.changes}
              element={<AddressChangesPage />}
            />
            <Route path={routerPaths.receipt} element={<ReceiptPage />} />
            <Route
              path={routerPaths.deliveryComplaint}
              element={<DeliveryComplaintPage />}
            />
            <Route path={routerPaths.payment} element={<ChangePaymentPage />} />
            <Route
              path={routerPaths.paymentHistory}
              element={<PaymentHistoryPage />}
            />
            <Route path={routerPaths.callback} element={<CallbackPage />} />
            <Route path={routerPaths.redirect} element={<RedirectPage />} />
          </Route>
          <Route path={routerPaths.error} element={<ErrorPage />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>
      ,
    </>,
  ),
);

const purchaseDisabledRouter = createBrowserRouter([
  {
    path: routerPaths.temporarilyUnavailable,
    element: (
      <AppContextProvider>
        <TemporarilyUnavailable />
      </AppContextProvider>
    ),
  },
  {
    path: "*",
    element: <Navigate to={routerPaths.temporarilyUnavailable} replace />,
  },
]);

const router = globalThis.indexHtmlEnvironmentConfig.purchaseDisabled
  ? purchaseDisabledRouter
  : mainRouter;

const App = () => <RouterProvider router={router} />;
export default App;
