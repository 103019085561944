import useSwr, { KeyedMutator } from "swr";

import { Locale } from "@/common/types";
import { FetcherError } from "@/fetch/errors";
import { fetcher } from "@/fetch/fetcher";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";

interface SubscriptionFetcherResult {
  forceRefetch: KeyedMutator<{ subscriptions: Subscription[] }>;
  isLoading: boolean;
  subscriptions?: Subscription[];
  error?: FetcherError | Error;
}

export const useSubscriptionFetcher = (
  locale: Locale,
  publicationDomain: string,
): SubscriptionFetcherResult => {
  const url = "/api/vorwerk/subscription";

  const fetchOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      publicationDomain,
      locale,
    }),
  };

  const { data, error, isLoading, mutate } = useSwr<
    { subscriptions: Subscription[] },
    Error
  >([url, fetchOptions], fetcher);

  return {
    forceRefetch: () => mutate(),
    isLoading,
    subscriptions: data?.subscriptions ?? undefined,
    ...(error && { error }),
  };
};
