import { goToLoginPage } from "@amedia/user";
import React, { useContext } from "react";
import { Outlet } from "react-router-dom";

import AppContext from "../../contexts/appContext";
import Help from "../Help";
import Spinner from "../Spinner";
import ErrorBlock from "../ErrorBlock";

import { SessionStatus, User } from "@/services/amedia-user/login";
import { log } from "@/logging/logger";
import { useSubscriptionFetcher } from "@/fetch/subscription/useSubscriptionFetcher";
import { useTranslate } from "@/i18n";

export interface AppOutlet {
  readonly user: User;
}

const AppRoute: React.FC = () => {
  const context = useContext(AppContext);
  const { locale, domain } = context.site;

  const t = useTranslate(messages);

  const { subscriptions, error, isLoading } = useSubscriptionFetcher(
    locale,
    domain,
  );

  if (error) {
    if (
      !context.subscriptions.error ||
      JSON.stringify(error) !== JSON.stringify(context.subscriptions.error)
    ) {
      log.error("Failed to fetch subscription <AppRoute/>", error);
      context.subscriptions.setError(error);
    }
  }

  if (
    subscriptions &&
    JSON.stringify(subscriptions) !== JSON.stringify(context.subscriptions.data)
  ) {
    context.subscriptions.set(subscriptions);
  }

  // Display footer when app is ready
  const footer = document.querySelector(
    "#vorwerk-footer-wrapper",
  ) as HTMLElement;
  if (footer) {
    footer.style.display = "block";
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (context.session.status === SessionStatus.SESSION_ERROR) {
    return (
      <>
        <ErrorBlock title={t("failedToLoadUser")}>
          <p>{t("errorMessage")}</p>
        </ErrorBlock>
        <Help />
      </>
    );
  }

  if (context.session.status === SessionStatus.SESSION_FOUND) {
    return (
      <div data-testid="app-outlet">
        <Outlet context={{ user: context.session.user }} />
        <Help />
      </div>
    );
  }
  if (context.session.status !== SessionStatus.UNINITIALIZED) {
    goToLoginPage({ context: "vorwerk" });
  }
  return <Spinner>Henter bruker...</Spinner>;
};

const messages = {
  failedToLoadUser: {
    nb: "Greide ikke laste inn brukerdata",
    nn: "Greidde ikkje lasta inn brukardata",
  },
  errorMessage: {
    nb: "Prøv igjen senere. Hvis problemet vedvarer, vennligst kontakt kundeservice.",
    nn: "Greidde ikkje lasta inn brukardata",
  },
};

export default AppRoute;
