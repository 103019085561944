import { ProductFeature } from "@/fetch/mappers/subscriptionMapper";

export const includesPlussalt = (productFeatures: ProductFeature[]) =>
  productFeatures.some(
    (pf) => pf.code === "plussalt" || pf.code === "pluss_alt",
  );

export const includesPaperDelivery = (productFeatures: ProductFeature[]) =>
  productFeatures.some(
    (pf) => pf.code === "paper_weekdays" || pf.code === "paper_weekend",
  );

export const includesShareable = (productFeatures: ProductFeature[]) =>
  productFeatures.some((pf) => pf.code === "shareable");
