export const messages = {
  title: {
    nb: "Beklager, noe gikk galt",
    nn: "Beklagar, noko gjekk gale",
  },
  text: {
    nb: "Beklager så mye, noe gikk galt i våre systemer, prøv igjen eller kontakt kundeservice dersom problemet vedvarer.",
    nn: "Beklagar så mykje, noko gjekk galt i våre system, prøv igjen eller kontakt kundeservice dersom problemet held fram",
  },
  retryButton: {
    nb: "Prøv igjen",
    nn: "Prøv igjen",
  },
  toFrontpageButton: {
    nb: "Til forsiden",
    nn: "Til framsida",
  },
  offeringsButton: {
    nb: "Se alle tilbud",
    nn: "Sjå alle tilbod",
  },
  customerService: {
    nb: "Kontakt kundesenteret",
    nn: "Kontakt kundesenteret",
  },
  customerServicePhone: {
    nb: "Telefon: {{phone}}",
    nn: "Telefon: {{phone}}",
  },
  customerServiceOpeningHours: {
    nb: "Åpningstider: 08.00 - 15.30 (mandag til fredag)",
    nn: "Opningstider: 08.00 - 15.30 (måndag til fredag)",
  },
  faq: {
    nb: "Ofte stilte spørsmål",
    nn: "Ofte stilte spørsmål",
  },
  request: {
    nb: "Send oss en henvendelse",
    nn: "Send oss ein førespurnad",
  },
};
